<template>
  <div>
    <AppModal
      v-if="showCreditCard || showDebitCard"
      v-model="model"
      :class="animationClass"
      :title="$t('ComponentAddPaymentMethodModal.TitleModal').value"
    >
      <template #header>
        <AppModalHeader>
          <template #right>
            <AppBackButton
              analytics-name="add-payment-method-modal-back"
              @click="$router.toParentRoute()"
            />
          </template>
          <h1>{{ $t('ComponentAddPaymentMethodModal.Header').value }}</h1>
        </AppModalHeader>
      </template>

      <AppList>
        <RiaListItem
          v-if="showCreditCard"
          analytics-name="add-payment-method-modal-creditcard"
          :title="$t('ComponentAddPaymentMethodModal.ButtonCreditCard').value"
          @itemClick="addCreditCard"
        >
          <template #figure-icon>
            <IconPurchase />
          </template>
        </RiaListItem>

        <RiaListItem
          v-if="showDebitCard"
          analytics-name="add-payment-method-modal-debitcard"
          :title="$t('ComponentAddPaymentMethodModal.ButtonDebitCard').value"
          @itemClick="addDebitCard"
        >
          <template #figure-icon>
            <IconPurchase />
          </template>
        </RiaListItem>
      </AppList>
    </AppModal>

    <AppModal
      v-if="showBankAccount && isDirectDebitEnabled"
      v-model="model"
      :class="animationClass"
      :title="$t('ComponentAddPaymentMethodModal.TitleModal').value"
      analytics-name="add-payment-method-modal-bankaccount"
    >
      <template #header>
        <XeModalHeader>
          <template #right>
            <XeBackButton
              analytics-name="add-payment-method-modal-back"
              @click="$router.toParentRoute()"
            />
          </template>
          <h1>
            {{ $t('ComponentAddPaymentMethodModal.DirectDebitText').value }}
            {{ `(${directDebitAcronym})` }}
          </h1>
        </XeModalHeader>
      </template>

      <template #figureTitle>
        <AppAlert v-if="isCorporateAccount" theme="yellow" class="mb-4">
          <span class="text-yellow-tertiary">
            <span class="align-sub">
              <AppIcon name=" " :size="18" color="red">
                <IconWarningAltFilled class="align-sub" />
              </AppIcon>
            </span>
            {{ $t('ComponentAddPaymentMethodModal.ListRowThree').value }}
            <span class="font-bold">
              {{ $t('ComponentAddPaymentMethodModal.ListRowThreeBold').value }}
            </span>
          </span>
        </AppAlert>
        <h2 class="type-h2">{{ $t('ComponentAddPaymentMethodModal.HowDoesItWork').value }}</h2>
      </template>

      <template #default>
        <div>
          <ol class="how-does-it-work-list">
            <li>
              {{ $t('ComponentAddPaymentMethodModal.ListRowOne').value }}
              <br />
              <AppLink
                class="plaid-user-privacy"
                href="https://plaid.com/legal/ios/"
                target="newtab"
                theme="primary"
              >
                {{ $t('ComponentAddPaymentMethodModal.PlaidUserPrivacyPolicy').value }}
              </AppLink>
            </li>
            <li>{{ $t('ComponentAddPaymentMethodModal.ListRowTwo').value }}</li>
            <li v-if="!isCorporateAccount">
              {{ $t('ComponentAddPaymentMethodModal.ListRowThree').value }}
              <span class="font-bold">
                {{ $t('ComponentAddPaymentMethodModal.ListRowThreeBold').value }}
              </span>
            </li>
          </ol>
        </div>
      </template>
      <template #footer>
        <AppModalFooter>
          <AppButton
            analytics-name="add-payment-method-modal-bankaccount"
            class="continue-button"
            @click="showAddBankAccountPlaid"
          >
            {{ $t('ComponentAddPaymentMethodModal.ButtonContinue').value }}
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>

    <AddBankAccountPlaidModal
      v-if="isPlaidInFlow"
      @event="onEvent"
      @success="onPlaidSuccess"
      @exit="onPlaidExit"
    />
    <AppOverlay :value="addBankAccountPlaid.loading || pageLoading">
      <AppSpinnerBig :loading="addBankAccountPlaid.loading || pageLoading" />
    </AppOverlay>
    <BankPaymentLimitReached v-model="showLimitReached" @change="changePaymentMethod()" />
    <PendingApprovalModal v-model="showPendingApprovalModal" />
  </div>
</template>

<script>
import { usePromiseLazy } from 'vue-composable'

import XeModalHeader from '@galileo/components/XeModalHeader/XeModalHeader'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import { computed, onBeforeMount, reactive, ref } from '@vue/composition-api'
import { useRouter, onBeforeRouteLeave } from '@galileo/composables/useRouter'
import { useAddPaymentMethods } from '@galileo/composables/usePaymentHelpers'
import RiaListItem from '@galileo/components/RiaListItem/RiaListItem'
import AddBankAccountPlaidModal from '@galileo/components/Views/PaymentMethod/AddBankAccountPlaidModal'
import BankPaymentLimitReached from '@galileo/components/Views/PaymentMethod/BankPaymentLimitReached'

import { PaymentMethod } from '@galileo/models/Transaction/app.js'

import PendingApprovalModal from '@galileo/components/Views/SendMoney/Amount/PendingApprovalModal.vue'

import {
  SEGMENT_LOCATIONS,
  SEGMENT_EVENTS,
  SEGMENT_PAYMENT_METHOD_TYPES,
  MICRO_DEPOSIT_TYPE,
} from '@galileo/constants/segmentAnalytics'

import { IconPurchase, IconFinance, IconWarningAltFilled } from '@oen.web.vue2/icons'
import { PLAID_CREATION_STATUS } from '@galileo/constants/bankAccCreationStatuses.const'
import { STEPS, DELIVERY_METHODS } from '@galileo/constants/sendMoneyFlow.const'
import { ACCOUNT_KYC_STATUS } from '@galileo/constants/accountKycStatus.const.ts'
import {
  useTransition,
  useVModel,
  AppOverlay,
  AppModal,
  AppModalHeader,
  AppModalFooter,
  AppList,
  AppBackButton,
  AppButton,
  useAlert,
  AppLink,
  AppSpinnerBig,
  AppAlert,
  AppIcon,
} from '@oen.web.vue2/ui'

import {
  useAppStore,
  useAnalyticsStore,
  useSendMoneyStore,
  useI18nStore,
  useAuthStore,
  useBankAccountStore,
  usePaymentsStore,
  useQuickTransferStore,
} from '@galileo/stores'

export default {
  name: 'PaymentMethodType',
  components: {
    AppAlert,
    AppIcon,
    AppModal,
    AppModalHeader,
    AppModalFooter,
    AppList,
    IconPurchase,
    IconFinance,
    IconWarningAltFilled,
    AppBackButton,
    RiaListItem,
    AddBankAccountPlaidModal,
    BankPaymentLimitReached,
    AppOverlay,
    AppSpinnerBig,
    AppButton,
    AppLink,
    XeModalHeader,
    XeBackButton,
    PendingApprovalModal,
  },
  emits: ['input'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    paymentMethodType: {
      type: String,
      default: null,
    },
    shouldOpenPlaid: {
      type: Boolean,
      default: false,
    },
    isSendMoney: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()
    const router = useRouter()
    const appStore = useAppStore()
    const alerter = useAlert()
    const userProfile = authStore.userProfile
    const isDirectDebitEnabled = ref(userProfile.customer.onlineDirectDebitEnabled)
    const bankAccountStore = useBankAccountStore()
    const sendMoneyStore = useSendMoneyStore()
    const quickTransferStore = useQuickTransferStore()

    const { isCorporateAccount } = authStore

    const showDirectId = ref(window.location.href.indexOf('localhost') >= 0)
    const showDebitCard = computed(() => {
      if (!props.paymentMethodType && !props.shouldOpenPlaid) {
        return true
      }

      return props.paymentMethodType === PaymentMethod.DebitCard.enumName
    })

    const showCreditCard = computed(() => {
      if (!props.paymentMethodType && !props.shouldOpenPlaid) {
        return true
      }

      return props.paymentMethodType === PaymentMethod.CreditCard.enumName
    })

    const showBankAccount = computed(() => {
      if (!props.paymentMethodType && !props.shouldOpenPlaid) {
        return true
      }

      return props.paymentMethodType === PaymentMethod.BankAccount.enumName
    })

    const pageLoading = ref(false)

    onBeforeMount(() => {
      if ((!isCorporateAccount || !isDirectDebitEnabled.value) && !props.isSendMoney) {
        router.toParentRoute()
      } else if (props.shouldOpenPlaid) {
        pageLoading.value = true
        showAddBankAccountPlaid()
      }
    })

    // Plaid block
    const billingAddress = reactive({
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      country: userProfile.country,
      street: userProfile.street,
      city: userProfile.city,
      state: userProfile.state,
      postalCode: userProfile.postalCode,
    })
    const isPlaidInFlow = ref(false)
    const animationClass = useTransition('blur', isPlaidInFlow)

    const showAddBankAccountPlaid = () => {
      const email = userProfile.customer.email
      const recipientCountry = sendMoneyStore.getRecipient?.country

      if (isDirectDebitEnabled.value) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.BANK_VERIFICATION_STARTED,
          traits: {
            location: props.isSendMoney ? SEGMENT_LOCATIONS.SEND_MONEY : SEGMENT_LOCATIONS.ACCOUNT,
            email: email,
            recipientCountry: recipientCountry,
            xemt: false,
          },
        })

        isPlaidInFlow.value = true
      } else {
        router.toParentRoute()
      }

      setTimeout(() => {
        pageLoading.value = false
      }, 3000)
    }

    //#region add plaid account

    const addBankAccountPlaid = reactive(
      usePromiseLazy((metadata) => {
        return bankAccountStore.addBankAccountPlaid({
          accountId: metadata.account.id,
          accountType: metadata.account.subtype,
          billingAddress: billingAddress,
          maskedAccountNumber: metadata.account.mask,
          bankName: metadata.institution.name,
          plaidMetaData: metadata,
        })
      })
    )

    const onPlaidSuccess = async (public_token, metadata) => {
      let verificationStatus = ''
      let microdepositType = MICRO_DEPOSIT_TYPE.AUTOMATED
      let sameDayMicrodeposits = false

      for (let item of metadata.accounts) {
        verificationStatus = item.verification_status
        if (verificationStatus === 'pending_manual_verification') {
          microdepositType = MICRO_DEPOSIT_TYPE.SAME_DAY
          sameDayMicrodeposits = true
        }
      }

      if (sameDayMicrodeposits || !verificationStatus) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.MICRO_DEPOSIT_LINK_PENDING,
          traits: {
            microdepositType: microdepositType,
          },
        })
      }

      // the event is trigger if we get a success verification through plaid
      // this event its not related to add the bank account to our db
      let recipientCountry = sendMoneyStore.getRecipient?.country

      analyticsStore.track({
        event: SEGMENT_EVENTS.BANK_VERIFICATION_SUCCESS,
        traits: {
          location: props.isSendMoney ? SEGMENT_LOCATIONS.SEND_MONEY : SEGMENT_LOCATIONS.ACCOUNT,
          recipientCountry: recipientCountry,
          xemt: false,
        },
      })
      analyticsStore.track({
        event: SEGMENT_EVENTS.NEW_PAYMENT_METHOD_ADDED,
        traits: {
          paymentType: SEGMENT_PAYMENT_METHOD_TYPES.BANK_ACCOUNT,
        },
      })
      // Remove the inflow check here since we will navigate away from the success event,
      // there are router guards that will prevent navigation if this is still set at true
      isPlaidInFlow.value = false
      // Add the account to the API
      await addBankAccountPlaid.exec(metadata)

      if (addBankAccountPlaid.error) {
        failedToAddPlaidAccount()
      } else {
        pageLoading.value = true
        plaidAccountAddeddSuccessfully()
      }
    }

    const showPendingApprovalModal = ref(false)

    const handleNotApprovedCorpAccount = () => {
      showPendingApprovalModal.value = true
      analyticsStore.track({
        event: SEGMENT_EVENTS.ACCOUNT_NOT_APPROVED,
        traits: {
          location: SEGMENT_LOCATIONS.SEND_MONEY,
        },
      })
    }

    const plaidAccountAddeddSuccessfully = async () => {
      const { bankAccount, creationStatus } = addBankAccountPlaid.result

      await usePaymentsStore().getPaymentMethods()

      if (creationStatus === PLAID_CREATION_STATUS.WAITING_CONFIRMATION) {
        if (router.currentRoute.meta.continueSendFlow) {
          router.replace({
            name: 'SendMoneyBankVerificationPendingModal',
            append: true,
            params: {
              isSendMoney: true,
            },
          })
          return
        }

        router.replace({
          name: 'BankVerificationPendingModal',
          append: true,
        })
        return
      }

      if (bankAccount.verificationStatus === 'Unverified') {
        //TODO improve logic - quick fix
        if (
          quickTransferStore.isCurrentTransactionQuickTransfer ||
          sendMoneyStore.form.deliveryMethod === DELIVERY_METHODS.FUNDS_ON_BALANCE
        ) {
          router.replace({
            path: 'not-verified',
            params: {
              isSendMoney: true,
              isNewAccount: true,
            },
          })
          return
        }

        if (router.currentRoute.meta.continueSendFlow) {
          router.replace({
            name: 'SendMoneyBankPaymentNotVerified',
            append: true,
            params: {
              isSendMoney: true,
              isNewAccount: true,
            },
          })
          return
        }

        router.replace({
          name: 'BankPaymentNotVerified',
          append: true,
        })

        return
      }

      const accountNotApproved =
        userProfile.customer.accountKycStatus === ACCOUNT_KYC_STATUS.NOT_APPROVED

      if (router.currentRoute.meta.continueSendFlow) {
        // IF SMF, set payment method and go to summary
        sendMoneyStore.form.paymentMethodId = bankAccount.id
        sendMoneyStore.setPaymentMethodName({
          paymentMethodTitle: bankAccount.bankName,
          paymentMethodCaption: bankAccount.maskedAccountNumber,
          paymentMethodText: bankAccount.bankName,
          paymentMethodNumber: bankAccount.maskedAccountNumber,
        })
        if (
          isCorporateAccount &&
          accountNotApproved &&
          !quickTransferStore.isCurrentTransactionQuickTransfer
        ) {
          handleNotApprovedCorpAccount()
          return
        }
      }

      if (props.isSendMoney) {
        router.replace({ name: STEPS.SEND_MONEY_SUMMARY })
      } else {
        router.toParentRoute()
      }
      alerter.add($t('ComponentAddPaymentBankModal.Notifications.BankAccountAdded').value)
    }

    const failedToAddPlaidAccount = () => {
      appStore.logException('Exception during adding bank account', addBankAccountPlaid.error)
      switch (addBankAccountPlaid.error.errorObject.reasonCode) {
        case 'ClientBankAccountNumberDuplicated':
          appStore.messageBoxGenericOk(
            $t('ComponentAddPaymentBankModal.Notifications.BankAccountAlreadyExists').value
          )
          break
        case 'ClientBankAccountLimitReached':
          showLimitReached.value = true
          break
        case 'PSAPI_021':
          if (router.currentRoute.meta.continueSendFlow) {
            router.replace({
              name: 'SendMoneyPlaidProductNotSupported',
              append: true,
            })
          } else {
            router.replace({
              name: 'PlaidProductNotSupported',
              append: true,
            })
          }
          break
        default:
          appStore.messageBoxGenericError()
      }
    }

    //#endregion

    const onPlaidExit = (err) => {
      if (err) {
        appStore.logException('Exception during adding bank account', err)
      }
      isPlaidInFlow.value = false
      router.toParentRoute()
    }
    const onEvent = (eventName, metadata) => {
      if (eventName === 'ERROR' && metadata && metadata?.error_code === 'INVALID_CREDENTIALS') {
        let email = authStore.user.customer.email
        let recipientCountry = sendMoneyStore.getRecipient.country

        analyticsStore.track({
          event: SEGMENT_EVENTS.BANK_VERIFICATION_FAILED,
          traits: {
            email: email,
            recipientCountry: recipientCountry,
            location: analyticsStore.paymentLocation,
            paymentVerificationMethod: 'Plaid',
          },
        })
      }
    }
    onBeforeRouteLeave((to, from, next) => {
      if (isPlaidInFlow.value) {
        isPlaidInFlow.value = false
        next(false)
      } else {
        next()
      }
    })

    const { addCreditCard, addDebitCard } = useAddPaymentMethods()

    const directDebitAcronym = computed(() => {
      const senderCountry = authStore.userProfile?.country
      if (senderCountry === 'CA') {
        return 'EFT'
      } else if (senderCountry === 'US') {
        return 'ACH'
      }
    })

    const showAddBankAccountDirectId = async () => {
      let email = authStore.user.customer.email
      let recipientCountry = sendMoneyStore.getRecipient.country

      analyticsStore.track({
        event: SEGMENT_EVENTS.BANK_VERIFICATION_STARTED,
        traits: {
          location: SEGMENT_LOCATIONS.SEND_MONEY,
          paymentVerificationMethod: 'DirectID',
          email: email,
          recipientCountry: recipientCountry,
          xemt: false,
        },
      })
      const customerRef = await bankAccountStore.getCustomerReference()
      window.location.href = `https://uk.connect.directid.co/?client_id=6756b16a-9fd5-4f1b-b813-7c96458180f9&config_id=US&customer_ref=${customerRef}`
    }

    const showLimitReached = ref(false)
    const changePaymentMethod = () => {
      router.toParentRoute()
    }

    return {
      pageLoading,
      addCreditCard,
      addDebitCard,
      showAddBankAccountPlaid,
      model,
      showDebitCard,
      showCreditCard,
      showBankAccount,
      showDirectId,
      showAddBankAccountDirectId,
      animationClass,
      directDebitAcronym,
      addBankAccountPlaid,
      isPlaidInFlow,
      onPlaidSuccess,
      onPlaidExit,
      onEvent,
      showLimitReached,
      changePaymentMethod,
      $t,
      isCorporateAccount,
      isDirectDebitEnabled,
      showPendingApprovalModal,
    }
  },
}
</script>

<style scoped>
.continue-button {
  @apply min-w-full;
}

.how-does-it-work-list {
  @apply list-decimal list-outside text-gray m-0 p-0;
  @apply ml-4 mt-4 mb-16;

  li {
    @apply mt-6;
  }
}
.plaid-user-privacy {
  @apply font-normal !important;
}

.align-sub {
  ::v-deep svg {
    vertical-align: sub;
  }
}
</style>
