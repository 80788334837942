<template></template>
<script>
import { onBeforeMount, onBeforeUnmount } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'
import { useBankAccountStore, useAppStore, useI18nStore } from '@galileo/stores'

export default {
  name: 'AddBankAccountPlaidModal',
  emits: ['exit', 'success', 'load', 'event'],
  setup(props, { emit }) {
    const i18nStore = useI18nStore()
    const router = useRouter()
    const appStore = useAppStore()
    let plaidInstance
    let settings = null
    const bankAccountStore = useBankAccountStore()

    onBeforeMount(async () => {
      try {
        settings = await bankAccountStore.plaidSettings(
          router.getParentRoute,
          router.currentRoute.meta
        )

        await appStore.loadScriptPlaid()
        plaidInstance = Plaid.create({
          clientName: 'XE',
          env: settings.environment,
          token: settings.linkToken,
          product: ['auth'],
          selectAccount: true,
          forceIframe: true,
          language: i18nStore.languageTagLanguage(i18nStore.i18n.locale),
          countryCodes: settings.countryCodes,
          onLoad: () => {
            emit('load')
          },
          onSuccess: async (public_token, metadata) => {
            emit('success', public_token, metadata)
            cleanUpPlaid()
          },
          onExit: (err) => {
            emit('exit', err)
            cleanUpPlaid()
          },
          onEvent: (eventName, metadata) => {
            appStore.logInfo('Plaid event', {
              eventName,
              metadata,
            })
            emit('event', eventName, metadata)
          },
        })
        plaidInstance.open()
      } catch (ex) {
        // TODO anything ?
        appStore.logException('Exception during getting plaid settings', ex)
        appStore.messageBoxGenericError()
        emit('exit', ex)
        cleanUpPlaid()
      }
    })

    onBeforeUnmount(() => {
      cleanUpPlaid()
    })

    const cleanUpPlaid = () => {
      plaidInstance && plaidInstance.destroy()
    }
  },
}
</script>
