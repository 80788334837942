<template>
  <information-app-modal
    v-model="model"
    button-title="Continue"
    @close="closeModal"
    :icon="ICON_TYPE.WARNING_YELLOW"
  >
    <h1 class="title">{{ $t('BankPaymentLimitReached.HeadingText').value }}</h1>

    <p>{{ $t('BankPaymentLimitReached.ParagraphText').value }}</p>

    <template #footer>
      <AppButton @click="changePaymentMethod()">
        {{ $t('BankPaymentLimitReached.ButtonChangePayment').value }}
      </AppButton>
      <AppButton theme="secondary" @click="navigateToPaymentMethods()">
        {{ $t('BankPaymentLimitReached.ButtonRemoveAccount').value }}
      </AppButton>
    </template>
  </information-app-modal>
</template>

<script>
import { useI18nStore } from '@galileo/stores'

import { useRouter } from '@galileo/composables/useRouter'
import InformationAppModal, {
  ICON_TYPE,
} from '@galileo/components/InformationAppModal/InformationAppModal'

import { useVModel, AppButton } from '@oen.web.vue2/ui'

export default {
  name: 'BankPaymentLimitReached',
  components: {
    AppButton,
    InformationAppModal,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const router = useRouter()

    const closeModal = () => {
      model.value = false
    }
    const changePaymentMethod = () => {
      closeModal()
      emit('change')
    }
    const navigateToPaymentMethods = () => {
      router.push({ name: 'AccountPaymentMethods' })
    }

    return {
      model,
      $t,
      closeModal,
      changePaymentMethod,
      navigateToPaymentMethods,
      ICON_TYPE,
    }
  },
}
</script>

<style scoped>
p {
  @apply text-base;
}
</style>
